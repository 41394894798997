import { UmbracoTypes } from '~/lib/data-contract';

/**
 * Maps reference items to media and text items
 *
 * @param items mixed models of IMediaAndTextItem & IPageReference
 * @returns Array of IMediaAndTextItem
 */
export const itemMapper = (
    items: (UmbracoTypes.IMediaAndTextItem | UmbracoTypes.IPageReference)[]
): UmbracoTypes.IMediaAndTextItem[] => {
    return items.map((item): UmbracoTypes.IMediaAndTextItem => {
        return isPageReference(item)
            ? {
                  headline: item.pageTitle,
                  description: `<p>${item.pageDescription}</p>`,
                  image: item.pageImage,
                  callToAction: item.callToAction,
              }
            : item;
    });
};

const isPageReference = (
    item: UmbracoTypes.IMediaAndTextItem | UmbracoTypes.IPageReference
): item is UmbracoTypes.IPageReference => {
    return item.alias === 'pageReference';
};
