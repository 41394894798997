import { useRawHtml } from './hooks';
import { StyledRichText } from './styled';

export type RawHtmlProps = {
    html?: string;
};

export const RawHtml = ({ html = '', ...rest }: RawHtmlProps) => {
    const markup = useRawHtml(html);

    return (
        <StyledRichText variant="paragraph" as="div" {...rest}>
            {markup}
        </StyledRichText>
    );
};
