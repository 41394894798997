import parse, {
    attributesToProps,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import NextLink from 'next/link';
import Script from 'next/script';
import { useMemo } from 'react';
import { UmbracoImage } from '~/shared/components/Image';
import { Link } from '~/shared/components/Link';
import { Text, TextVariants } from '~/shared/components/Text';
import { StyledTabel } from '../styled';

type RegularTextTags = (typeof regularTextTags)[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

const options: HTMLReactParserOptions = {
    /**
     * Required to prevent warning about whitespace in tables - if tables are used.
     * Might removed intended whitespace.
     *
     * @see https://github.com/remarkablemark/html-react-parser#trim
     */
    trim: true,

    /**
     * Replace HTML tags with react components
     * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
     */
    replace: (domNode) => {
        const { attribs, children, name } = domNode as Element;
        const props = attributesToProps(attribs || {});

        if (!attribs) {
            return;
        }

        if (regularTextTags.includes(name as RegularTextTags)) {
            const tag = name as RegularTextTags;
            return (
                <Text {...props} as={tag}>
                    {domToReact(children, options)}
                </Text>
            );
        }

        if (
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(
                name as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
            )
        ) {
            const size = Number(name.replace(/\D/g, ''));
            const asTag = name as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

            return (
                <Text {...props} as={asTag} variant={`display${size}` as TextVariants}>
                    {domToReact(children, options)}
                </Text>
            );
        }

        if (name === 'a') {
            // Umbraco files/images always starts with /media
            return props.href.startsWith('/media') ? (
                <Link {...props}>{domToReact(children, options)}</Link>
            ) : (
                <NextLink href={props.href} passHref legacyBehavior>
                    <Link {...props}>{domToReact(children, options)}</Link>
                </NextLink>
            );
        }

        if (name === 'img') {
            const isRelative = props.src.startsWith('/');
            const src = isRelative
                ? `${process.env.NEXT_PUBLIC_UMBRACO_IMAGES_URL}${props.src}`
                : props.src;

            return (
                <UmbracoImage
                    src={src}
                    alt={props.alt}
                    width={Number(props.width)}
                    height={Number(props.height)}
                />
            );
        }

        if (name === 'table') {
            return <StyledTabel {...props}>{domToReact(children, options)}</StyledTabel>;
        }

        if (name === 'script') {
            return <Script {...props}>{domToReact(children, options)}</Script>;
        }
    },
};

export const useRawHtml = (html: string) => {
    return useMemo(() => {
        return parse(html, options);
    }, [html]);
};
