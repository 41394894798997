import styled from '@emotion/styled';
import { StyledText } from '~/shared/components/Text';
import { RawHtmlProps } from './RawHtml';

export const StyledRichText = styled(StyledText)<RawHtmlProps>(({ theme }) => ({
    color: theme.colors.black,
    img: {
        height: 'auto',
        maxWidth: '100%',
    },

    [`p, ol, ul, pre, table`]: {
        marginBottom: theme.space[3],
    },

    p: {
        minHeight: '1em',
    },

    li: {
        marginBottom: theme.space[2],
    },

    ol: {
        paddingLeft: 25,
        listStyle: 'decimal',
    },

    ul: {
        paddingLeft: 25,
        listStyle: 'disc',
    },

    code: {
        fontFamily: theme.fonts.mono,
    },

    pre: {
        backgroundColor: theme.colors.grey10,
        padding: 15,
        fontFamily: theme.fonts.mono,
    },
    [`${StyledText}`]: {
        color: 'inherit',
    },
}));

export const StyledTabel = styled(StyledText.withComponent('table'))(({ theme }) => ({
    minWidth: '100%',
    borderCollapse: 'collapse',

    // Tables
    'td, th': {
        padding: '0.75em',
        textAlign: 'left',
        border: `1px solid ${theme.colors.grey30}`,
        boxSizing: 'border-box',
    },

    th: {
        fontWeight: theme.fontWeights.medium,
        backgroundColor: theme.colors.grey10,
    },

    thead: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
    },

    'tbody > tr > th:first-of-type': {
        position: 'sticky',
        left: 0,
    },
}));
