import { useMemo } from 'react';
import { UmbracoTypes } from '~/lib/data-contract';
import { ModuleCarousel } from '../../shared/ModuleCarousel';
import { Article } from './components/Article';
import { itemMapper } from './utils/itemMapper';

export type M80MediaTextBundleProps = UmbracoTypes.IM80MediaAndTextBundleModule;

export const M80MediaTextBundle = ({
    headline,
    callToAction,
    mediaAndTextItems,
    ...rest
}: M80MediaTextBundleProps) => {
    const articles = useMemo(() => {
        const items = itemMapper(mediaAndTextItems);
        return items.map((item, index) => <Article key={index} {...item} />);
    }, [mediaAndTextItems]);

    return <ModuleCarousel items={articles} headline={headline} link={callToAction} {...rest} />;
};
