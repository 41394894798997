import styled from '@emotion/styled';
import { Link } from '~/shared/components/Link';
import { RawHtml } from '~/shared/components/RawHtml';
import { Text } from '~/shared/components/Text';
import { cover } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';

export const StyledArticle = styled.div({
    position: 'relative',
    width: '230px',
    [breakpoints.md]: {
        width: '320px',
    },
});

export const StyledContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.space[4],
}));

export const StyledImageWrapper = styled.div({
    position: 'relative',
    width: '230px',
    height: '230px',
    overflow: 'hidden',
    [breakpoints.md]: {
        width: '320px',
        height: '320px',
    },
});

export const StyledClampedHTML = styled(RawHtml)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    marginBottom: theme.space[4],
    maxWidth: '80%',

    'p:last-of-type': {
        marginBottom: 0,
    },
}));

export const Label = styled(Text)(({ theme }) => ({
    marginBottom: theme.space[1],
}));

export const StyledLink = styled(Link)({
    ':after': {
        content: '""',
        ...cover,
    },
});

export const StyledHeadline = styled(Text)(({ theme }) => ({
    marginBottom: theme.space[4],
    maxWidth: '85%',
}));
