import NextLink from 'next/link';
import { UmbracoTypes } from '~/lib/data-contract';
import { UmbracoImage } from '~/shared/components/Image';
import { Text } from '~/shared/components/Text';
import { useModule } from '~/templates/blocks/hooks/useModule';
import { queries } from '~/theme/breakpoints';
import {
    Label,
    StyledArticle,
    StyledClampedHTML,
    StyledContent,
    StyledHeadline,
    StyledImageWrapper,
    StyledLink,
} from './styled';

export type ArticleProps = UmbracoTypes.IMediaAndTextItem;

export const Article = ({
    headline,
    label,
    image,
    description,
    callToAction,
    ...rest
}: ArticleProps) => {
    const { index } = useModule();

    return (
        <StyledArticle {...rest}>
            <StyledImageWrapper>
                {image ? (
                    <UmbracoImage
                        alt={headline || ''}
                        src={image.src}
                        fill
                        style={{ objectFit: 'cover' }}
                        sizes={`${queries.md} 320px, 230px`}
                        draggable={false}
                        priority={index < 2}
                    />
                ) : null}
            </StyledImageWrapper>
            <StyledContent>
                {label ? <Label variant="paragraph">{label}</Label> : null}

                {headline ? <StyledHeadline variant="display4">{headline} </StyledHeadline> : null}

                {description ? <StyledClampedHTML html={description} /> : null}

                {callToAction ? (
                    <Text variant="uiBtn">
                        <NextLink href={callToAction.url} passHref legacyBehavior>
                            <StyledLink
                                href={callToAction.url}
                                target={callToAction.target}
                                draggable={false}
                                withIcon
                            >
                                {callToAction.text}
                            </StyledLink>
                        </NextLink>
                    </Text>
                ) : null}
            </StyledContent>
        </StyledArticle>
    );
};
